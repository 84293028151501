.event-details_page {
    background-color: #fafaff;
    .image-slider_container {
        position: relative;
        // style gallery slider
        .image-gallery-content {
            height: 60vh;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to bottom, hsla(234, 17%, 12%, 0.19), #191a23);
            }
            .image-gallery-thumbnail-inner{
                height: 100%;
            }
            .image-gallery-thumbnail-image{
                height: 100%;
                object-fit: cover;
            }

            .image-gallery-thumbnails .image-gallery-thumbnails-container {
                z-index: 2;
                position: relative;
            }
            .image-gallery-thumbnail{
                height: 55px;
                @include ease;
            }
            .image-gallery-image {
                height: 60vh;
                max-height: 60vh;
                object-fit: cover;
            }
            &.fullscreen {
                .image-gallery-image {
                    height: 100vh;
                    max-height: 100vh;
                }
            }
            .image-gallery-thumbnails {
                position: absolute;
                right: 9vw;
                bottom: 3vh;
            }
            .image-gallery-icon {
                svg {
                    display: none;
                }
                &.image-gallery-right-nav,
                &.image-gallery-left-nav {
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 30px;
                    bottom: -20px;
                    position: absolute;
                    top: auto !important;
                }
                &.image-gallery-right-nav {
                    right: 9vw;
                    background-image: url("../../../assets/images/arrow-right.svg");
                }
                &.image-gallery-left-nav {
                    background-image: url("../../../assets/images/icons/arrow-left.svg");
                    right: calc(9vw + 3rem);
                    left: auto;
                }
            }
            .image-gallery-thumbnail.active,
            .image-gallery-thumbnail:focus,
            .image-gallery-thumbnail:hover {
                border: 1px solid #5bc3df;
            }
            .image-gallery-thumbnail:hover {
                border: 4px solid #5bc3df;
            }
        }

        // style content
        .content {
            position: absolute;
            left: 9vw;
            bottom: 2vh;
            color: white;
            max-width: 45%;
            .chip {
                background-color: $sec-color;
                width: max-content;
            }
        }
    }

    // /event-details
    .event-details {
        hr {
            background: #b6b9d4;
        }
        .text {
            font-size: 13px;
        }
        .description {
            color: #5a5e6e;
        }
        .rules {
            list-style-type: none;
            li {
                position: relative;
                &::before {
                    content: url("../../images/icons/bullet.svg");
                    position: absolute;
                    left: -1.8rem;
                    top: 0px;
                    [dir="rtl"] & {
                        right: -1.8rem;
                        left: auto;
                        scale: -1;
                    }
                }
            }
        }

        .sponsers {
            img {
                max-width: 100px;
            }
        }
        //video-container
        .video-container {
            video {
                height: 300px;
            }
        }

        video,
        iframe {
            border-radius: 10px;
        }
        .map iframe {
            height: 194px;
        }
    }

    //ticket-prices
    .ticket-prices {
        box-shadow: 0 14px 50px rgba(182, 185, 212, 0.1);
        border-radius: 10px;
        li {
            &:not(:last-of-type) {
                border-bottom: 1px solid hsla(234, 26%, 77%, 0.3);
            }
        }
        .text {
            font-size: 11px;
            max-width: 85%;
            text-transform: none;
        }
        span {
            font-size: 14px;
            &:first-child {
                color: #5a5e6e;
                flex: 1;
            }
            &.price {
                color: $sec-color;
            }
        }
    }

    // need help
    .need-help {
        box-shadow: 0 14px 50px rgba(182, 185, 212, 0.1);
        border-radius: 10px;
        .title {
            color: #000;
            font-size: 30px;
            line-height: 45px;
        }
        .main-button {
            background-color: #ec6d47;
            border-color: #ec6d47;
            &:hover{
                opacity: .9;
            }
        }
    }

    //top-events
    .top-events{
        box-shadow: 0 14px 50px rgba(182, 185, 212, 0.1);
        border-radius: 10px;
        .title {
            color: #000;
            font-size: 30px;
            line-height: 45px;
        }
    }
}

//
@media screen and (min-width: 1400px) {
    .sponsers {
        img {
            max-width: 160px;
        }
    }
}
@media screen and (min-width: 1200px) {
    .event-details_page {
        .image-slider_container {
            // style gallery slider
            .image-gallery-content {
            }

            // style content
            .content {
                .title {
                    font-size: 45px;
                    line-height: 58px;
                }
            }
        }
        //main-img
        .main-img{
            height: 350px;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
        // /event-details
        .event-details {
            .title {
                font-size: 24px;
            }
            .text {
                font-size: 18px;
            }

            .map iframe {
                height: 533px;
            }
        }

        //ticket-prices
        .ticket-prices {
            .text {
                font-size: 13px;
            }
            span {
                font-size: 20px;
            }
        }

    }
}

// mobile
@media screen and (max-width: 767px) {
    .event-details_page {
        .image-slider_container {
            // style gallery slider
            .image-gallery-content {
            }

            // style content
            .content {
                left: 6vw;
                max-width: 90%;
                bottom: auto;
                top: 8vh;
                .title {
                    font-size: 30px;
                    line-height: 44px;
                }
            }
        }
        .event-details {
            .sponsers {
                img {
                    max-width: 58px;
                }
            }
        }
    }
}


// evenet details , first side box shadow & bg #fff