.event-card-top-container {
  .event-card-container {
    position: relative;
    .main-img {
      height: 370px;
      width: 100%;
      border-radius: 10px;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background: linear-gradient(
        180deg,
        rgba(60, 62, 87, 0) 0%,
        rgba(29, 30, 44, 0.94) 71.63%
      );
    }
  }
  .content {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    text-align: center;
    p {
      color: $sec-color;
      font-weight: 500;
      font-size: .8rem;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      &::after {
        content: "";
        bottom: 4px;
        width: 22%;
        height: 1px;
        background-color: #b6b9d4;
        margin: auto;
      }
    }
    h3 {
      font-size: 1.5rem;
      line-height: 52px;
      margin-bottom: 0.2rem;
    }
    span {
      color: #b6b9d4;
      font-weight: 500;
      font-size: 1.1rem;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .event-card-top-container {
    .event-card-container {
    .main-img {
      height: 400px;
    }
  }
  }
}
@media only screen and (min-width: 1600px) {
  .event-card-top-container {
    .event-card-container {
      // .main-img {
      //   height: 525px;
      // }
    }
  }
}
