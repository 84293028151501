.loading-container {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999999999999;
    backdrop-filter: blur(2px);
    background: rgba(35, 38, 47, 0.2);
    color: $main-color;
    .spinner-grow {
        width: 3rem;
        height: 3rem;
    }
}
