@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?9zlclq');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?9zlclq#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?9zlclq') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?9zlclq') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?9zlclq##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iticket-icon-"], [class*=" iticket-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iticket-icon-right {
  &:before {
    content: $iticket-icon-right; 
  }
}
.iticket-icon-eye {
  &:before {
    content: $iticket-icon-eye; 
  }
}
.iticket-icon-close {
  &:before {
    content: $iticket-icon-close; 
  }
}
.iticket-icon-google {
  &:before {
    content: $iticket-icon-google; 
  }
}
.iticket-icon-apple {
  &:before {
    content: $iticket-icon-apple; 
  }
}
.iticket-icon-minues {
  &:before {
    content: $iticket-icon-minues; 
  }
}
.iticket-icon-right-arrow-solid {
  &:before {
    content: $iticket-icon-right-arrow-solid; 
  }
}
.iticket-icon-right-arrow-backup-2 {
  &:before {
    content: $iticket-icon-right-arrow-backup-2; 
  }
}
.iticket-icon-arrow-down {
  &:before {
    content: $iticket-icon-arrow-down; 
  }
}
.iticket-icon-arrow-left-large {
  &:before {
    content: $iticket-icon-arrow-left-large; 
  }
}
.iticket-icon-arrow-small-right {
  &:before {
    content: $iticket-icon-arrow-small-right; 
  }
}
.iticket-icon-plus {
  &:before {
    content: $iticket-icon-plus; 
  }
}
.iticket-icon-arrow-left {
  &:before {
    content: $iticket-icon-arrow-left; 
  }
}
.iticket-icon-arrow-right {
  &:before {
    content: $iticket-icon-arrow-right; 
  }
}
.iticket-icon-search {
  &:before {
    content: $iticket-icon-search; 
  }
}
.iticket-icon-twitter {
  &:before {
    content: $iticket-icon-twitter; 
  }
}
.iticket-icon-youtube {
  &:before {
    content: $iticket-icon-youtube; 
  }
}
.iticket-icon-calendar {
  &:before {
    content: $iticket-icon-calendar; 
  }
}
.iticket-icon-facebook {
  &:before {
    content: $iticket-icon-facebook; 
  }
}
.iticket-icon-insta {
  &:before {
    content: $iticket-icon-insta; 
  }
}
.iticket-icon-location-blue .path1 {
  &:before {
    content: $iticket-icon-location-blue-path1;  
    color: rgb(182, 185, 212);  
    opacity: 0.4;
  }
}
.iticket-icon-location-blue .path2 {
  &:before {
    content: $iticket-icon-location-blue-path2;  
    margin-left: -1em;  
    color: rgb(182, 185, 212);
  }
}
.iticket-icon-map-pin .path1 {
  &:before {
    content: $iticket-icon-map-pin-path1;  
    color: rgb(25, 26, 35);
  }
}
.iticket-icon-map-pin .path2 {
  &:before {
    content: $iticket-icon-map-pin-path2;  
    margin-left: -0.9091796875em;  
    color: rgb(255, 255, 255);
  }
}

