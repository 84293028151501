.faq-page {
    .header {
        .subtitle {
            color: #5a5e6e;
        }
        .title {
            line-height: 40px;
        }
    }

    // faqs
    .accordion-item {
        min-height: 55px;
        border: 1px solid rgba(90, 94, 110, 0.3);
        border-radius: 8px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .accordion-header {
            width: 100%;
            .accordion-button {
                color: #191a23;
                font-size: 13px;
                font-weight: 500;
                background-color: transparent;
                box-shadow: none !important;
            }
        }

        .accordion-button:not(.collapsed)::after {
            filter: grayscale(1);
        }

        // accordion-body
        .accordion-body {
            color: #5a5e6e;
            font-size: 13px;
            @include ease;
            border-top: 1px solid #191a233d;
            padding: 0.8rem 0 ;
            margin: 0rem 1.25rem;
        }

        .accordion-collapse{
            width: 100%;
        }
    }

    @media screen and (min-width: 1200px) {
        .header {
            .title {
                line-height: 50px;
            }
        }

        // faqs
        .accordion-item {
            min-height: 60px;
            border-radius: 12px;
            padding: .3rem;
            .accordion-header {
                .accordion-button {
                    font-size: 18px;
                }
            }
            // accordion-body
            .accordion-body {
                font-size: .9rem;
                line-height: 26px;
                padding: 1rem 0 0;
                margin: .5rem 1.25rem;
            }
        }
    }

    // mobile
    @media screen and (max-width: 767px) {
        .header {
            .title {
                font-size: 24px;
            }
            .subtitle {
                font-size: 13px;
            }
        }
    }
}
