.auth-modal {
  .modal-content {
    border-radius: 10px 10px 52px 10px;
    position: relative;
    &::before {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $sec-color;
      content: "";
    }
  }
  h3 {
    font-size: 1.4rem;
    text-align: center;
    color: #191a23;
  }
  .close-icon {
    display: flex; 
    justify-content: flex-end;
    padding: 20px;
    span {
      border: 1.33333px solid #e8e9f3;
      border-radius: 10px;
      padding: 10px;
      font-size: 0.7rem;
      @include ease;
      &:hover{
        background-color: #e8e9f3;
      }
    }
  }
  .buttons-container {
    button {
      color: #fff;
      border-radius: 10px;
      padding: 10px 23px;
      &:nth-child(1) {
        background-color: $sec-color;
      }
      &:nth-child(2) {
        background-color: $dark-color;
      }
    }
  }
  span.show-pass {
    position: absolute;
    right: 25px;
    top: 17px;
    color: #9598ae;
  }
  .sign-up-text {
    color: #9598ae;
    font-family: $poppins-regular;
    font-size: 1rem;
  }
  .pass-input {
    .form-control.is-invalid {
      background: unset;
    }
  }
  span.iticket-icon-right{
    display: block;
    text-align: center;
    font-size: 4.8rem;
    color: #00CE92;
  }
  &.reset-pass-container{
    margin: 40px auto;
  }
}
@media (min-width: 767px) {
  .auth-modal {
    h3 {
      font-size: 1.6rem;
    }
    .buttons-container {
      button {
        padding: 10px 33px;
      }
    }
    &.reset-pass-container{
      width: 40%;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

}

html[dir="rtl"] {
  .auth-modal {
    .modal-content {
      border-radius: 10px 10px 10px 52px;
      &::before {
        left: 0;
        right: auto;
      }
    }
    // span.show-pass {
    //   left: 25px;
    //   right: auto;
    // }
  }
}
