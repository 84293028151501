// -----------------------------------------------------------------------------
// This file contains all font declarations, if any.
// -----------------------------------------------------------------------------

//font-wieght 300
@font-face {
  font-family: 'Poppins-Light';
  src: url('../../fonts/Poppins/Poppins-Light.ttf');
}
//font-wieght 400
@font-face {
    font-family: 'Poppins-Regular';
    src: url('../../fonts/Poppins/Poppins-Regular.ttf'); 
  }
//font-wieght 500
@font-face {
    font-family: 'Poppins-Medium';
    src: url('../../fonts/Poppins/Poppins-Medium.ttf');
  }
//font-wieght 600
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../../fonts/Poppins/Poppins-SemiBold.ttf');
}
//font-wieght 700
@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../fonts/Poppins/Poppins-Bold.ttf');
  }