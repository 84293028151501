.banner-search-input {
  height: 50px;
  width: 100%;
  .input-group-text {
    background: #fff;
    border-radius: 10px 0 0 10px;
    border: 2px solid #e8e9f3;
    border-right: none;
    width: 15%;
    display: inline-block;
    padding-top: 14px;
  }
  span {
    &.iticket-icon-search {
      font-size: 22px;
    }
  }
  input {
    margin: 0 !important;
    border: 2px solid #e8e9f3;
    border-radius: 0 10px 10px 0;
    border-left: none;
    width: 85%;
    &::placeholder {
      color: #9598ae;
      font-size: 16px;
    }
  }
  .rmdp-range {
    background-color: rgba(25, 26, 35, 0.05);
    box-shadow: none;
    color: #5a5e6e;
  }
}
.previous-main-events-container {
}
@media only screen and (min-width: 992px) {
  .banner-search-input {
    width: max-content;
  }
  .previous-main-events-container {
  }
}

html[dir="rtl"] {
  //dropdown
  .banner-search-input {
    .input-group-text {
      border-radius: 0 10px 10px 0;
      border-left: none;
      border-right: 2px solid #e8e9f3;
    }
    input {
      border-radius: 10px 0 0 10px;
      border-right: none;
      border-left: 2px solid #e8e9f3;
    }
  }
}
