.confirm-tickets-container {
  position: relative;
  .tickets-swiper-container {
    overflow: visible;
    @media (max-width: 767px) {
      overflow: hidden;
      .swiper {
        padding-bottom: 20px;
        .swiper-pagination {
          bottom: -4px;
        }
      }
    }
    .swiper-wrapper {
      .swiper-slide {
        margin-top: 0 !important;
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: -64px;
      font-weight: bold;
      &::after {
        font-size: 0.8rem;
        display: none;
      }
      span{
        color: #000;
        font-size: 1.5rem;
      }
      &.swiper-button-disabled {
        opacity: .4;
      }
      &:hover {
        // background-color: $blue-color;
        color: #fff;
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
      right: 0;
      left: auto;
    }
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      right: 60px;
      left: auto;
      img {
        transform: scale(-1);
      }
    }
  }
}
