// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
footer {
    background-color: #191a23;
    .footer-head {
        color: #fff;
        font-weight: 600;
    }
    .footer-mobile {
        display: none;
    }
    .footer-links {
        a {
            color: $text-light;
            font-family: $poppins-light;
            font-size: 14px;
            @include ease;
         
            &:hover {
                color: $main-color;
                border-inline-start: 2px solid $main-color;
                padding-inline-start: 0.5rem;
            }
        }
        &.social{
            .icon {
                width: 20px;
                display: inline-flex;
                justify-content: center;
            }
            .text{
                margin-inline-start: .75rem;
            }
        }
    }
    .join-us {
        .header {
            font-size: 1.2rem;
            color: #fff;
        }
        .input-container {
            input {
                border: 1px solid #484959;
                color: #fff;
                font-size: 12px;
            }
            .icon {
                position: absolute;
                right: 8px;
                background-color: transparent;
                border: none;
                top: 13px;
                @include ease;
                color: white;
                &:hover {
                    color: $main-color;
                    transform: scale(1.2) translateX(3px);
                }
            }
        }
        .feedback{
            font-size: 14px;
            color: #66f566;
        }
        .mob-apps {
            .title {
                color: $text-light;
                font-size: 15px;
            }
            a:first-child {
                margin-inline-end: 1rem;
            }
            img {
                width: 138px;
                height: 46px;
            }
        }
    }
    .footer-bottom{
        position: relative;
        &::after {
            position: absolute;
            content: "";
            width: 100%;
            inset: 0 0 auto;
            height: 1px;
            background: linear-gradient(
                to right,
                hsla(226, 35%, 76%, 0.02),
                hsla(227, 35%, 76%, 0.2),
                hsla(226, 35%, 76%, 0.02)
            );
        }
        .footer-logo{
            width: 141px;
        }
        .copywrite{
            font-size: 13px;
            margin-inline-start: .75rem;
        }
        img.paymentMethods{
            object-fit: contain;
        }
    }
    @media only screen and (min-width: 1200px) {
        .footer-head {
            font-size: 1rem;
        }
        .join-us {
            .header {
                font-family: $poppins-medium;
                font-size: 1rem;
            }
            .mob-apps {
                a {
                    margin-inline-end: 1rem;
                }
                img {
                    width: 138px;
                    height: 46px;
                }
            }
        }
    }

    // small screens{
    @media screen and (max-width: 576px) {
        .footer-desktop {
            display: none;
        }
        .footer-mobile {
            display: block;
        }
        .accordion-item,
        .accordion-button,
        .accordion-button:not(.collapsed) {
            background-color: transparent;
            border: none;
        }
        .accordion-item {
            padding: .75rem 0;
            position: relative;
            &::after {
                position: absolute;
                content: "";
                width: 100%;
                inset: auto 0 0;
                height: 1px;
                background: linear-gradient(
                    to right,
                    hsla(227, 35%, 76%, 0.2),
                    hsla(227, 35%, 76%, 0.2),
                    hsla(227, 35%, 76%, 0.2)
                );
            }
            ul li {
                &:first-child {
                    margin-top: 0.5rem;
                }
            }
        }
        .accordion-button {
            box-shadow: none;
            padding: 0;
            &:focus {
                box-shadow: none;
            }
        }
        .accordion-button::after {
            filter: brightness(0) invert(1);
        }
        .accordion-body {
            padding: 0;
        }
        .copywrite{
            font-size: .7rem;
        }
        .join-us {
            .header {
                font-size: 1.3rem;
                color: #fff;
            }
            .input-container {
                position: relative;
                input {
                    height: 50px;
                    border-radius: 10px;
                    border: 1px solid #484959;
                    background-color: transparent;
                    color: #fff;
                    &::placeholder {
                        transition: all 2s ease;
                    }
                    &:focus {
                        &::placeholder {
                            opacity: 0;
                            transform: translateY(10px);
                        }
                    }
                }
                .icon {
                    position: absolute;
                    right: 8px;
                    background-color: transparent;
                    border: none;
                    top: 14px;
                    @include ease;
                    color: white;
                    &:hover {
                        color: $main-color;
                        transform: scale(1.2) translateX(3px);
                    }
                }
            }
            .mob-apps {
                img {
                    width: 100%;
                    height: 47px;
                    object-fit: scale-down;
                }
            }
        }
    }
}
