/**
 * Basic typography style for text
 */

body,
a,
p {
 font-family: $poppins-medium;
}
span {
  font-family: $poppins-regular;
 }

h1,
h2,
h3,
h4,
.font-weight-bold {
  font-family: $Poppins-Bold; // 700
}
h5,
h6,
.font-weight-medium {
  font-family: $poppins-medium; // 500
}
.font-weight-light{
  font-family: $poppins-light; //300
}
.font-weight-regular{
  font-family: $poppins-regular; //400
}
.font-weight-semiBold{
  font-family: $poppins-semiBold; //600
}

// for colors
.cl-main{
  color: $main-color;
}
.cl-light{
  color: $text-light;
}

