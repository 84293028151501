.sell-on-ticket_page {
  .header {
    .subtitle {
      color: #5a5e6e;
    }
    .title {
      line-height: 40px;
    }
  }
  .input-container {
    select {
        border: 2px solid #e8e9f3;
      &:focus {
        border-color: #563e91;
        box-shadow: none;
        outline: none;
      }
    }
  }

  .main-img {
    max-width: 213px;
  }

  @media screen and (min-width: 1200px) {
    .header {
      .title {
        line-height: 50px;
      }
    }
    .main-img {
      max-width: 392px;
    }
  }

  // mobile
  @media screen and (max-width: 767px) {
    .header {
      .title {
        font-size: 24px;
      }
      .subtitle {
        font-size: 13px;
      }
    }
  }
}
