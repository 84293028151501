.otp-input {
  width: 100% !important;
  direction: ltr;
  .styles_react-code-input__CRulA {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    input {
      background: transparent;
      border: 2px solid #e8e9f3;
      border-radius: 10px;
      margin-right: 15px;
      // color: $white-color;
      &:focus {
        border: none;
        border-bottom: 2px solid $dark-color;
        caret-color: $white-color;
        border-radius: 0;
      }
    }
  }
  // &.light {
  //   .styles_react-code-input__CRulA {
  //     input {
  //       border-radius: 4px !important;
  //       background: $white-color;
  //       border: 1px solid $light-gray-color;
  //       margin-right: 15px;
  //       color: $gray-color;
  //       &:focus {
  //         border: 1px solid $main-color;
  //         caret-color: $gray-color;
  //       }
  //     }
  //   }
  // }
}
.settings-page {
  .settings-header {
    border-bottom: 1px solid rgba(182, 185, 212, 0.3);
    .wallet-box {
      border: 1px solid #e8e9f3;
      box-shadow: 0 14px 50px rgba(182, 185, 212, 0.1);
      border-radius: 8px;
      padding: 0.5rem 1rem 0.5rem 0.5rem;

      .img {
        background-color: $sec-color;
        width: 46px;
        height: 40.5px;
        margin-inline-end: 0.5rem;
        border-radius: 6px;
      }
      .wallet-title {
        font-size: 9px;
      }
      .price {
        font-size: 11.25px;
        line-height: 16.88px;
      }
    }
  }

  .settings-content {
    hr {
      border: 0.3px solid #b6b9d4;
    }
    form {
      label {
        font-size: 13px;
        line-height: 20px;
        text-transform: uppercase;
        color: #9598ae;
        font-family: $poppins-semiBold;
      }
      .pass-info {
        p {
          color: #191a23;
          font-size: 13px;
          font-family: $poppins-semiBold;
          margin-bottom: 5px;
        }
        span {
          color: #9598ae;
          font-size: 15px;
          font-family: $poppins-regular;
        }
        a {
        }
        input {
          &.form-check-input {
            padding: 11px;
            width: 43.38px;
            &:checked {
              background-color: $main-color;
              border: 1px solid $main-color;
            }
            &:focus{
              box-shadow: none;
              outline: none;
              border: none;
            }
            &:not(:checked){
              background-color: rgba(149, 152, 174, 0.4);
              border: none;
            }
          }
        }
      }
      .verified-container {
        position: absolute;
        top: 45px;
        right: 12px;
        color: #00ce92;
        font-size: 13px;
      }
      .unverified-container {
        position: absolute;
        top: 36px;
        right: 0.75rem;
        font-size: 13px;
        width: 93px;
      }
    }

    //
    .presonal-info_input{
      .main-button{
        border-radius: 6px;
      }
    }
  }

  //personal-info
  .personal-info {
    .user-img {
      .user-avatar {
        width: 160px;
        height: 160px;
        position: relative;
        img {
          padding: 3px;
          width: 157px;
          height: 157px;
          z-index: 5;
          object-fit: cover;
          background-color: #fff;
        }
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          inset: 0;
          border-radius: 50%;
          background: linear-gradient(to bottom, #573e91, #573e9115);
        }
      }
      .img-input {
        width: max-content;
        position: relative;
        cursor: pointer;
        input {
          cursor: pointer;
          position: absolute;
          width: 100%;
          height: 100%;
          inset: 0;
          opacity: 0;
        }
        .text {
          margin-inline-start: 0.75rem;
          color: #5a5e6e;
          font-size: 15px;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .settings-header {
      .title {
        line-height: 60px;
        font-size: 40px;
      }

      .wallet-box {
        .img {
          width: 62px;
          height: 54px;
        }
        .wallet-title {
          color: #9c9ea8;
          font-size: 12px;
        }
        .price {
          font-size: 15px;
          line-height: 22.5px;
        }
      }
    }

    .settings-title {
      font-size: 21px;
      .icon {
        margin-inline-end: 2px;
      }
    }
    .settings-content {
      form {
        .border-container {
          border-left: 0.3px solid rgba(182, 185, 212, 0.3);
        }
      }
    }
  }

  @media only screen and (min-width: 1600px) {
      .pass-info {
        .change-pass {
          max-width: 30%;
        }
      }
  }

  // mobile only
  @media screen and (max-width: 767px) {
    .settings-header {
      .title {
        line-height: 36px;
        font-size: 24px;
      }
      .wallet-box {
        .img {
          img {
            width: 21px;
            height: 21px;
          }
        }
      }
    }

    .settings-title {
      .icon {
        margin-inline-end: 0.3rem;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .settings-content {
      form {
        .border-container {
          border-top: 0.3px solid rgba(182, 185, 212, 1);
          padding-top: 25px;
          margin-top: 25px;
        }
      }
    }

    //personal-info
    .personal-info {
      .user-img {
        .user-avatar {
          width: 80px;
          height: 80px;
          margin-inline-end: 1.5rem;
          img {
            width: 78px;
            height: 78px;
          }
        }
        .img-input .text {
          margin-inline-start: 0.5rem;
          font-size: 14px;
        }
      }
    }
  }
}
html[dir="rtl"] {
  .settings-page {
    @media screen and (min-width: 1200px) {
      .settings-content {
        form {
          .border-container {
            border-right: 0.3px solid rgba(182, 185, 212, 0.3);
            border-left: none;
          }
        }
      }
    }
  }
}
