.banner-container {
  background-image: url("../../images/Rectangle.png");
  background-position: center center;
  background-size: cover;
  height: 72vh;
  .banner-container-content {
    background: linear-gradient(
      90deg,
      #191a23 0%,
      rgba(25, 26, 35, 0.79) 0.01%,
      rgba(25, 26, 35, 0.97) 53.69%,
      rgba(25, 26, 35, 0.74) 99.99%,
      rgba(25, 26, 35, 0) 100%
    );
    height: 100%;
    .banner-title{
      span{
        position: relative;
        img{
          position: absolute;
          inset: auto 0 -14px;
        }
      }
    }
  }
  .banner-container-text {
    width: 90%;
    padding-top: 50px;
    // z-index: 7;
    p {
      background: linear-gradient(
        97.12deg,
        #ff0ba8 -9.05%,
        #34cefb 44.71%,
        #f08b41 99.6%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-size: 1.6rem;
    }
    h5 {
      font-size: 2.5rem;
    }
    form {
      width: 100%;
      z-index: 2;
      // display: none;
      &.absoluteForm {
        display: block;
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        bottom: 0;
        background-color: #fff;
        z-index: 10;
      }
    }
  }
  //dropdown
  .banner-search-input {
    height: 50px;
    .input-group-text {
      background: #fff;
      border-radius: 10px 0 0 10px;
      border: none;
      border-right: none;
      width: 15%;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 6px;
    }
    span {
      &.iticket-icon-search {
        font-size: 22px;
      }
    }
    input {
      margin: 0 !important;
      border: none !important;
      // border-radius: 0 10px 10px 0 !important;
      border-radius: 0 10px 10px 0;
      width: 85%;

      &::placeholder {
        color: #191a23;
        font-size: 16px;
      }
    }
    .rmdp-range {
      background-color: rgba(25, 26, 35, 0.05);
      box-shadow: none;
      color: #5a5e6e;
    }
  }
  .search-dropDowns-container {
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    padding-top: 4px;
    hr {
      border-bottom: 1px solid #b6b9d4;
    }
    h3 {
      text-align: initial;
      color: #5a5e6e;
      font-size: 1.3rem;
    }
    .search-dropdowns-home {
      .input-group-text {
        background-color: #fff;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .event-parent-container {
      max-height: 300px;
      overflow-y: auto;
      text-align: initial;
      .event-container {
        img {
          width: 42px;
          height: 42px;
          object-fit: cover;
          border-radius: 10px;
        }
        h6 {
          color: #191a23;
          font-size: 1.1rem;
        }
      }
    }
  }
}
////////////////////////// medium screens
@media only screen and (min-width: 500px) {
  .banner-container {
    height: 92vh;
    .banner-search-input {
      .input-group-text {
        width: 10%;
      }
      input {
        width: 90%;
      }
    }
    .banner-container-text {
      form {
        display: block;
        &.absoluteForm {
          display: block;
          width: 100%;
          position: initial;
          background: unset;
          z-index: 1;
        }
      }
    }
  }
  .search-dropDowns-container {
    margin-top: -10px;
    border-top: 1px solid #b6b9d4;

  }
}
////////////////////////// medium screens
@media only screen and (min-width: 1200px) {
  .banner-container {
    height: 100vh;
    .banner-container-text {
      width: 50%;
      &.padding-tp{
        padding-top: 119px;
      }
      h5 {
        font-size: 3rem;
      }
    }
  }
}
////////////////////////// large screens
@media only screen and (min-width: 1600px) {
  .banner-container {
    height: 90vh;
    .banner-container-text {
      width: 40%;
    }
    .banner-search-input {
      height: 73px;
    }
  }
}
html[dir="rtl"] {
  //dropdown
  .banner-search-input {
    .input-group-text {
      border-radius: 0 10px 10px 0;
    }
    input {
      border-radius: 10px 0 0 10px;
    }
  }
}
