.not-found_page {
    min-height: 100vh;
    .text {
        color: #5a5e6e;
    }

    @media screen and (max-width: 767px) {
        img{
            width: 237px;
        }
        .title{
            font-size: 24px;
        }
        .text{
            font-size: 14px;
        }
        .main-button{
            width: 100%;
        }
    }
}
