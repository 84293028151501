.main-button {
  border-radius: 10px;
  padding: .4rem 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  @include ease;
  &.primary{
    background: $main-color;
    color:$white-color;
    border: 2px solid  $main-color;
    &:hover{
      background-color: lighten($main-color, 5);
    }
  }
  &.secondary{
    color:$text-color;
    border: 2px solid #E8E9F3;
    background-color: transparent;
    &:hover{
      background-color: $main-color;
      border-color: $main-color;
    }
  }

  &:hover{
    color: #fff;
  }
  &.lg{
    height: 44px;
    font-size: .9rem;
  }
 
}
////////////////////////// medium screens
@media only screen and (min-width: 1200px) {
    .main-button {
        padding: .5rem 1.3rem;
        &.lg{
          height: 50px;
          font-size: 1rem;
        }
    }
  }
////////////////////////// large screens
@media only screen and (min-width: 1600px) {
    .main-button {
        padding: 0.64em 2.9rem;
    }
  }
