.card-container {
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    // opacity: 0.5;
    border-radius: 10px;
  }
  h6 {
    font-size: 1rem;
    color: #191A23;
  }
  .event-duration {
    width:max-content;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #484959;
    background: rgba(72, 73, 89, 0.1);
    border-radius: 8px;
    height: max-content;
    padding: 4px 1.3rem;
  }
  .date-container {
    margin: 0;
    span {
      color: $gray-color;
      font-size: 0.8rem;
      &:nth-child(2) {
        width: 4px;
        height: 4px;
        background: $gray-color;
        border-radius: 50%;
        margin: 4px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .card-container {
    img {
      height: 231;
    }
    h6 {
     &:hover{
        color:$main-color
      }
    }
    .event-duration {
      width: 46%;
      padding: 5px 11px;
    }
  }
}
@media only screen and (min-width: 1600px) {
  .card-container {
    img {
    }
  }
}
