.upcoming-events-container {
  .upcoming-events-container-header {
    width: 100%;
    .filter-container {
      width: 100%;
    }
    .upcoming-events-container-header-child {
      width: 100%;
    }
  }
}
.previous-events-container {
  background-image: url("../../images//Rectangle\ 4.png");
  background-position: center center;
  background-size: cover;
  background-attachment: local;
  position: relative;
  padding-bottom: 12rem !important;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(284.23deg, rgba(25, 26, 35, 0.87) 28.12%, rgba(25, 26, 35, 0.56) 145.23%);
  }
  .relative {
    position: relative;
    height: 80px;
    h3 {
      position: absolute;
      z-index: 2;
    }
  }
  .events-swiper-container {
    overflow: visible;
    @media (max-width: 767px) {
      overflow: hidden;
      .swiper {
        padding-bottom: 20px;
        .swiper-pagination {
          bottom: -4px;
        }
      }
    }
    .swiper-wrapper {
      .swiper-slide {
        margin-top: 0 !important;
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: -64px;
      font-weight: bold;
      &::after {
        font-size: 0.8rem;
        display: none;
      }
      &.swiper-button-disabled {
        display: none;
      }
      &:hover {
        // background-color: $blue-color;
        color: #fff;
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
      right: 0;
      left: auto;
    }
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      right: 100px;
      left: auto;
      img {
        transform: scale(-1);
      }
    }
  }
}
.icon-slider-container {
  border: 1.5px solid #191a23;
  border-radius: 18px;
  cursor: pointer;
  @include ease;
  min-height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.active {
    border-color: $main-color;
    pointer-events: none;
    h6 {
      color: $main-color;
    }
    img{
      filter: invert(23%) sepia(43%) saturate(2302%) hue-rotate(235deg) brightness(87%) contrast(83%);
    }
  }
  img {
    width: 38px;
    height: 38px;
    @include ease;
  }
  h6 {
    font-size: 0.9rem;
    margin-top: 0.4rem;
  }
}

// style partners-container
.partners-container {
  box-shadow: 0 34px 140px rgba(182, 185, 212, 0.2);
  border-radius: 10px;
  margin-top: -7rem;
  z-index: 2;
  position: relative;
  background-color: white;
  &::after,
  &::before {
    content: "";
    position: absolute;
    right: -1px;
    bottom: 0;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  }
  &::after {
    width: 24px;
    height: 24px;
    background-color: #f3f3f7;
    box-shadow: 0 34px 140px rgba(182, 185, 212, 0.2);
  }

  &::before {
    width: 14px;
    height: 14px;
    background-color: #f08b41;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    z-index: 2;
  }
  .content {
    @include imageBackground("../../../assets/images/partners-banner.png");
    position: relative;
    height: 127px;
    border-radius: 10px;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background: linear-gradient(284.23deg, rgba(25, 26, 35, 0.87) 28.12%, rgba(25, 26, 35, 0.56) 145.23%);
    }
    & > * {
      position: relative;
      z-index: 2;
    }
    .logo {
      width: 107px;
    }
    .title {
      color: white;
      font-size: 0.9rem;
    }
  }
}

.select-location-dropdown {
  .css-2kfbha-MenuList {
    max-height: 250px;
    &::-webkit-scrollbar {
      width: .3rem;
    }
  }
  .css-xg64wg-menu {
    width: 126%;
    left: -44px;
    border-radius: 8px !important;
    overflow: hidden;
  }
  .css-1lblj0v-option, .css-w02p1w-option{
    width: 100%;
  }
  .css-w02p1w-option{
     color: $main-color;
  }
  // .css-1gvuuw5-contro,.css-177cwkc-control{
  //   width: 100%;
  // }
}
///////////////////
@media only screen and (min-width: 1200px) {
  .upcoming-events-container {
    .upcoming-events-container-header {
      width: 75%;
      .filter-container {
        width: max-content;
      }
      .upcoming-events-container-header-child {
        width: max-content;
      }
    }
  }
  .previous-events-container {
    .relative {
      height: 130px;
    }
  }
  .icon-slider-parent-container {
    width: 80%;
    margin: auto;
    .icon-slider-container {
      overflow: hidden;
      width: 100px;
      &:hover{
        img{
          transform: scale(1.1) rotate(-15deg);
        }
      }
    }
  }
  // style partners-container
  .partners-container {
    .content {
      height: 350px;
      .title {
        max-width: 70%;
        font-size: 1.4rem;
      }
      .logo {
        width: 253px;
      }
      // &::after {
      //   width: 80px;
      //   height: 80px;
      // }

      &::before {
        width: 50px;
        height: 50px;
      }
    }
    .partners-imgs {
      .partners-title {
        font-size: 34px;
      }
      img {
        width: 85%;
        margin: auto;
      }
    }
  }
}

///////////////////
@media only screen and (min-width: 1600px) {
  .container {
    max-width: 1240px;
  }
  .icon-slider-container {
    width: 116px;
    img {
      width: 48px;
      height: 48px;
    }
  }
}

//mobile
@media screen and (max-width: 800px) {
  .upcoming-events-container {
  
    .input-group-merge {
      font-size: 12px;
      input {
        height: 42px !important;
        &::placeholder {
          font-size: 12px;
        }
      }
      span.iticket-icon-calendar {
        font-size: 18px;
      }
      img {
        width: 11px;
      }
    }
  }
  .icon-slider-container {
    width: 69.75px;
    border-radius: 10.8px;
    margin: 0.75rem 0;
    img {
      width: 28.8px;
      height: 28.8px;
      margin-bottom: 0.6rem;
    }
    h6 {
      font-size: 10px;
    }
  }
}
///////////// arabic
html[dir="rtl"] {
  .select-location-dropdown {
    
    .css-xg64wg-menu {
      left: auto;
      right: -44px;
      
    }
    
  }
  .icon-slider-parent-container{
   direction: ltr;
  }
}
