// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
/// dark layout
.dark-header {
  header {
    background-color: $text-color;
    .logoDark {
      display: none;
    }
  }
}
/// white layout
.white-header {
  header {
    background-color: #fff;
    box-shadow: 0 14px 50px rgba(182, 185, 212, 0.1);
    .navbar-dark .navbar-nav .nav-link,
    .nav-item a.signUp {
      color: #9598ae;
      &.active {
        color: $text-color;
        // pointer-events: none;
      }
    }
    .last-side-container {
      a.signUp {
        color: $text-color;
      }
    }
    .logoWhite {
      display: none;
    }
    .btnLine {
      background-color: #000;
    }
  }
}
//// banner layout
.banner-layout {
  header {
    .logoDark {
      display: none;
    }
  }
}
header {
  padding: 10px 0;
  position: fixed;
  inset: 0 0 auto;
  z-index: 9;
  min-height: 80px;
  nav {
    @include ease;
  }
  &.navScroll {
    box-shadow: 2px 0px 50px rgba(102, 127, 168, 0.164);
    backdrop-filter: blur(2px);
    // background: linear-gradient(rgba(35, 38, 47, 0.8) 0%, rgba(18, 20, 23, 1) 100%);
    min-height: 80px;
    background: linear-gradient(rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 1) 100%);
    z-index: 11;
    .logoDark {
      display: block;
    }
    .logoWhite {
      display: none;
    }
    .navbar-dark .navbar-nav .nav-link,
    .nav-item a.signUp {
      color: #9598ae;
      @include ease;
      &:hover {
        color: $main-color;
      }
    }
    nav {
      padding: 0;
    }
  }
  .middle-side-container {
    a {
      font-size: 1rem;
    }
  }
  .last-side-container {
    img {
      width: 2rem;
    }

    a {
      font-size: 1rem;
      color: #fff;
    }
  }
  .lang-img {
    width: 50px;
    height: 37px;
  }
  .navbar-brand {
    display: flex;
    padding: 0;
  }
}
@media only screen and (min-width: 1200px) {
  header {
    padding: 5px 0;
    z-index: 999;
    .lang-desktop {
      display: block !important;
    }
    .lang-mob {
      display: none !important;
    }
    .navbar-nav {
      justify-content: space-evenly;
    }
    .middle-side-container {
      a {
        // margin-right: 35px;
      }
    }
  }
}
@media only screen and (min-width: 1600px) {
  header {
    padding: 30px 0;
    .middle-side-container {
      a {
        margin-right: 60px;
      }
    }
    .lang-img {
      width: 60px;
    }
  }
}

// style on mobile
@media only screen and (max-width: 1200px) {
  .linksContainer {
    flex-direction: column;
    justify-content: center;
    backdrop-filter: blur(2px);
    // background: linear-gradient(rgba(35, 38, 47, 0.8) 0%, rgba(18, 20, 23, 1) 100%);
    min-height: 100vh;
    right: 0;
    left: 0;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 8;
    margin: 0;
    @include ease;
    transform: translateX(100%);
    transform-origin: right;
    background: linear-gradient(rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 1) 100%);
    overflow-y: auto;
    overflow-x: hidden;
    /* style scrollbar */
    &::-webkit-scrollbar {
      width: 0em;
    }

    ul {
      &.middle-side-container {
        flex-direction: column !important;
        align-items: center;
        margin-bottom: 2rem;
      }
    }
    &.open-nav {
      transform: translateX(0%);
      ul.middle-side-container li {
        margin: 0.5rem 0;
      }
      .navbar-nav .nav-link,
      .nav-item a.signUp {
        color: #9598ae;
      }
    }
  }
  .navbar-brand {
    position: fixed;
    left: 1.5rem;
    top: 1.1rem;
  }
}
.lang-mob {
  position: fixed;
  right: 5.5rem;
  top: 1.3rem;
}

.menuBtn {
  display: none; /////////////////
  position: fixed;
  right: 2.1rem;
  top: 1.6rem;
  cursor: pointer;
  z-index: 9;
  @include ease;
  @media (max-width: 1200px) {
    display: block;
  }
  @media (max-width: 400px) {
    right: 1rem;
  }
}
.closeMenu {
  // rotate LINE 1
  & :nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  // hide LINE 2
  & :nth-child(2) {
    opacity: 0;
    transform: translateX(20px);
  }
  // rotate LINE 3
  & :nth-child(3) {
    transform: rotate(-45deg) translate(11px, -11px);
  }
}
.btnLine {
  width: 1.9rem;
  height: 0.3rem;
  margin: 0 0 0.4rem 0;
  border-radius: 8px;
  @include ease;
  background-color: #fff;
  .navScroll & {
    background-color: #000;
  }
}

///////////////////////////////////// arabic

html[dir="rtl"] {
  header {
  }
  @media only screen and (min-width: 1200px) {
    header {
      .middle-side-container {
        a {
          // margin-left: 35px;
          margin-right: 0;
        }
      }
    }
  }
  @media only screen and (min-width: 1600px) {
    header {
      .middle-side-container {
        a {
          margin-left: 60px;
          margin-right: 0;
        }
      }
    }
  }
}

// style notification
.notification-nav {
  position: relative;
  .notification {
    position: relative;
    cursor: pointer;
    .noti {
      position: absolute;
      top: -8px;
      right: -15px;
    }
    html[dir="rtl"] & {
      .noti {
        right: auto;
        left: -15px;
      }
    }
  }
  .noti {
    display: inline-block;
    width: 11px;
    height: 11px;
    background-color: $sec-color;
    border-radius: 50%;
    margin-inline-end: 0.4rem;
  }

  .notification-nav_content {
    position: absolute;
    background: white;
    border-radius: 10px;
    box-shadow: 0 34px 130px rgba(0, 0, 0, 0.2);
    width: 400px;
    top: 50px;
    right: calc(50% - 200px);
    @include ease;
    transform: scaleY(00);
    transform-origin: top;
    opacity: 0;
    &.show {
      transform: scaleY(1);
      opacity: 1;
      z-index: 5;
    }
    .title {
      font-size: 18px;
    }
    ul {
      max-height: 470px;
      overflow: auto;
      &::-webkit-scrollbar-thumb {
        background-color: #9b9b9c;
      }
    }
    li {
      cursor: pointer;
      &:not(:last-child) {
        border-bottom: 1.3px solid #e4e8ee;
      }
      &:not(.active) {
        .noti {
          background-color: #b6b9d4;
        }
      }
    }
    .noti-title {
      font-size: 14px;
      line-height: 21px;
    }
    .time {
      font-size: 12px;
      color: #a5acb8;
    }
    @media screen and (max-width: 1200px) {
      width: 280px;
      right: calc(50% - 140px);
      transform: translateY(-100%);
      top: calc(50vh - 200px);
      position: fixed;
      &.show {
        transform: translateY(0);
      }
      .title {
        font-size: 15px;
      }
      ul {
        max-height: 300px;
      }
      .noti {
        width: 8px;
        height: 8px;
      }
      .noti-title {
        font-size: 12px;
        line-height: 18px;
      }
      .time {
        font-size: 10px;
      }
    }
  }
}
header .last-side-container {
  .profile-nav {
    position: relative;
    .user-avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      cursor: pointer;
    }
    .profile-nav_content {
      position: absolute;
      background: white;
      border-radius: 10px;
      box-shadow: 0 34px 140px rgba(182, 185, 212, 0.2);
      width: 285px;
      top: 60px;
      right: calc(50% - 142.5px);
      @include ease;
      transform: scaleY(0);
      transform-origin: top;
      opacity: 0;
      &.show {
        transform: scaleY(1);
        opacity: 1;
      }
      li {
        .icon {
          margin-inline-end: 1rem;
          img {
            width: 1.4rem;
          }
        }
        a {
          color: #5a5e6e;
          font-size: 0.9rem;
        }

        &:last-of-type {
          border-bottom: 1px solid #b6b9d4;
        }
      }
      .main-button {
        height: 42px;
      }
      @media screen and (max-width: 1200px) {
        width: 280px;
        right: calc(50% - 142.5px);
        transform: translateY(-100%);
        top: 40vh;
        position: fixed;
        &.show {
          transform: translateY(0);
        }
      }
    }
  }
}
