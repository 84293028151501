// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html,
body {
  height: 100%;
  color: $text-color;
  /* style scrollbar */
  ::-webkit-scrollbar {
    width: .6em;
    height: .4em;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 100px;
    background-color: #d7d7db;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $main-color;
    border-radius: 35px;
    background-clip: padding-box;
  }
}
body {
  background-color: #fbfbfd;
}
/**
 * Basic styles for links
 */
a {
  text-decoration: none;
  color: $main-color;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  &.btn {
    line-height: 2rem;
  }
}
