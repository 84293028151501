.book-ticket_page {
  overflow: hidden;
  // steps
  .steps {
    .line {
      position: relative;
      &:not(:last-child) {
        width: 193px;
        height: 3px;
        background-color: #e8e9f3;
      }
      &:after {
        content: "";
        position: absolute;
        width: 13px;
        height: 13px;
        background-color: #e8e9f3;
        border-radius: 50%;
        top: calc(50% - 6.5px);
        left: calc(50% - 110px);
      }
      &:last-child {
        &:after {
          right: calc(50% - 4px);
          left: auto;
        }
      }
      &.active {
        &:after {
          background-color: #00ce92;
          border: 7px solid #ccf5e9;
          width: 27px;
          height: 27px;
          top: calc(50% - 13.5px);
        }
        &.done {
          background-color: #00ce92;
        }
      }
    }
    .titles {
      // width: 500px;
      .title {
        width: 193px;
        text-align: center;
        margin: 0;
        &.active {
          color: #00ce92;
        }
      }
    }
  }

  // book-body
  .book-body {
    color: #191a23;
    .title {
      font-size: 22px;
      line-height: 36px;
      border-bottom: 1px solid hsla(234, 26%, 77%, 0.4);
    }

    .box {
      border: 1px solid #e8e9f3;
      cursor: pointer;
      font-size: 13.5px;
      line-height: 21px;
      &.active {
        background-color: #ea582d;
        color: #fff;
        pointer-events: none;
      }
    }
    // date-container
    .date-container {
      .date-box {
        width: 92.25px;
        height: 108.75px;
        border-radius: 7.5px;
      }
    }

    // time-container
    .time-container {
      .time-box {
        width: 116px;
        height: 54px;
        border-radius: 10px;
      }
    }

    // tickets
    .tickets {
      .ticket {
        user-select: none;
        &:not(:last-of-type) {
          border-bottom: 1px solid hsla(234, 26%, 77%, 0.4);
        }
        .text {
          font-size: 11px;
          color: #9c9ea8;
          max-width: 75%;
          padding-inline-end: 0.76rem;
        }
        .actions {
          span {
            user-select: none;
            font-size: 0.8rem;
            display: inline-block;
            width: 15px;
            text-align: center;
            &.iticket-icon-minues {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 2rem;
            }
          }
          .num-box {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid hsla(234, 26%, 77%, 0.4);
            width: 34.45px;
            height: 33.89px;
            border-radius: 6.5px;
            cursor: pointer;
            @include ease;
            svg {
              @include ease;
            }
            &.disabled {
              pointer-events: none;
              span {
                color: #e8e9f3;
              }
            }
          }
        }
      }
    }

    // payment-type
    .payment-type {
      .label {
        font-size: 14px;
      }
      .form-check {
        padding-left: 0;
        margin-inline-end: 1rem;
        input {
          display: none;
        }
        label {
          font-size: 12px;
          @include ease;
          padding: 6px 0;
        }
        input:checked ~ label {
          padding: 6px 15px;
          background-color: #191a23;
          color: white;
        }
      }
    }

    // checkbox
    .checkbox {
      .form-check-input {
        width: 26px;
        height: 26px;
        box-shadow: none;
        &:checked {
          background-color: $sec-color;
          border-color: $sec-color;
        }
      }
      label {
        padding-top: 0.2rem;
        padding-inline-start: 1rem;
        font-weight: 500;
        user-select: none;
      }
    }

    // line
    .line {
      width: 0.3px;
      background-color: #b6b9d4ad;
      height: 100%;
    }
    // promo-code form
    .promo-code {
      .input-container {
        button.main-button {
          position: absolute;
          right: 0.5rem;
          top: 8px;
          font-size: 11px;
          height: 40px;
        }
      }
    }

    // payment input
    .payment-input {
      position: relative;
      .icon {
        position: absolute;
        right: 1rem;
        top: 1rem;
        img{
          width: 30px;
        }
      }
    }
  }

  // style booking-ticket_card
  .booking-ticket_card {
    box-shadow: 0 27.68px 114px rgba(182, 185, 212, 0.2);
    border-radius: 8.14px;
    img {
      height: 127px;
      border-radius: 8.14px;
    }
    .badge {
      background-color: $sec-color;
      font-size: 9.77px;
      height: 19.45px;
      width: 79.8px;
      border-radius: 8.14px;
    }
    .title {
      font-size: 17.91px;
    }
    .border-dashed {
      border: 2px dashed #e8e9f375;
      position: relative;
      &::after,
      &::before {
        position: absolute;
        content: "";
        width: 30px;
        height: 30px;
        background-color: #f5f5f9;
        border-radius: 50%;
        top: -15px;
      }
      &::after {
        right: -30px;
      }
      &::before {
        left: -30px;
      }
    }
    .details {
      .label {
        color: #9598ae;
        line-height: 29.54px;
        font-size: 13.3px;
      }
      .info {
        font-size: 12.21px;
        line-height: 18.32px;
      }
    }
  }

  // style summary
  .summary {
    .title {
      font-size: 20px;
    }
    .summary-details {
      span {
        font-size: 12.22px;
        line-height: 18.33px;
        &:first-child {
          color: #5a5e6e;
        }
      }
    }
    .total {
      background-color: #f3f4f8;
      height: 43.17px;
    }
  }
}
.border-dashed {
  border: 2px dashed #e8e9f375;
  position: relative;
  &::after,
  &::before {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    background-color: #f5f5f9;
    border-radius: 50%;
    top: -15px;
  }
  &::after {
    right: -30px;
  }
  &::before {
    left: -30px;
  }
}
@media screen and (min-width: 1200px) {
  .book-ticket_page {
    // steps
    .steps {
    }

    // book-body
    .book-body {
      .title {
        font-size: 32px;
        line-height: 60px;
      }
      .subtitle {
        font-size: 20px;
      }
      .box {
        border-radius: 10px;
        font-size: 1rem;
        line-height: 27px;
      }
      // date-container
      .date-container {
        .date-box {
          width: 105px;
          height: 95px;
        }
      }

      // time-container
      .time-container {
        .time-box {
          width: 120px;
          height: 40px;
        }
      }

      // tickets
      .tickets {
        .ticket {
          .text {
            font-size: 15px;
          }
          .actions {
            span {
              font-size: 1.2rem;
              &.iticket-icon-plus {
                font-size: 0.8rem;
              }
            }
            .num-box {
              width: 43px;
              height: 42px;
              svg {
                font-size: 28px;
              }
              &:hover {
                background-color: $sec-color;
                span {
                  color: #fff;
                  transform: scale(1.2);
                }
              }
            }
          }
        }
      }

      // payment-type
      .payment-type {
        .label {
          font-size: 20px;
        }
      }
    }
    .date-box,
    .time-box {
      @include ease;
      &:hover {
        background-color: #e8e9f3;
      }
    }
    // style booking-ticket_card
    .booking-ticket_card {
      box-shadow: 0 34px 140px rgba(182, 185, 212, 0.2);
      border-radius: 10px;
      img {
        height: 157px;
        border-radius: 10px;
      }
      .badge {
        font-size: 12px;
        height: 24px;
        width: 98px;
        border-radius: 10px;
      }
      .title {
        font-size: 1.1rem;
      }
      .details {
        .label {
          line-height: 24px;
        }
        .info {
          font-size: 15px;
          line-height: 22.5px;
        }
      }
    }

    // style summary
    .summary {
      .title {
        font-size: 1rem;
      }
      .summary-details {
        span {
          font-size: 15px;
          line-height: 22.5px;
        }
      }
      .total {
        height: 53px;
      }
    }
  }
}
// mobile
@media screen and (max-width: 767px) {
  .book-ticket_page {
    // steps
    .steps {
      .line {
        &:not(:last-child) {
          width: 116px;
          height: 2px;
        }
        &:after {
          width: 9.75px;
          height: 9.75px;
          top: calc(50% - 4.875px);
          left: calc(50% - 58px);
        }
        &:last-child {
          &:after {
            right: calc(50% - 4px);
            left: auto;
          }
        }
        &.current {
          &:after {
            border: 5px solid #ccf5e9;
            width: 20px;
            height: 20px;
            top: calc(50% - 10px);
          }
        }
      }
      .titles {
        .title {
          width: 116px;
          font-size: 12px;
        }
      }
    }

    .book-body {
      // line
      .line {
        width: 100%;
        height: 0.3px;
        margin: 1.5rem 0 1rem;
      }
    }
  }
}

// arabic style
html[dir="rtl"] {
  .book-ticket_page {
    // steps
    .steps {
      .line {
        &:after {
          right: calc(50% - 110px);
          left: auto;
        }
        &:last-child {
          &:after {
            left: calc(50% - 4px);
            right: auto;
          }
        }
      }
    }
  }
}
