.ticket-card-container-parent {
  .ticket-card-container {
    padding: 14px;
    margin-bottom: 23px;
    border-radius: 10px;
    box-shadow: -5px 1px 7px 1px #f7f7f7, 4px -2px 8px 1px #f7f7f7;
    img {
      max-width: 100%;
      &.barcode {
        width: 82.36px;
        height: 82.36px;
      }
      &.tick-img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        mix-blend-mode: normal;
      }
    }
    .tick-img-container {
      position: relative;
      height: 104.27px;
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: absolute;
        border-radius: 10px;
        background: linear-gradient(
          180deg,
          rgba(25, 26, 35, 0.62) -62.15%,
          #191a23 100%
        );
        opacity: 0.3;
      }
    }
    // .img-barcode {
    //   border-bottom: 1.32832px dashed #e8e9f3;
    // }
    span {
      background: #ea582d;
      border-radius: 10px;
      color: #fff;
      font-weight: 600;
      font-size: 0.6rem;
      padding: 6px 14px;
    }
    h2 {
      font-size: 1.2rem;
      @include trim(2);
    }
    h5 {
      color: #9598ae;
      font-size: 0.8rem;
      font-family: $poppins-semiBold;
    }
    h6 {
      font-size: 0.8rem;
      @include trim(1);
      font-family: $poppins-semiBold;
    }
  }
  .card-summery {
    p {
      color: #9598ae;
      font-family: $poppins-semiBold;
    }
    .buttons-container {
      button {
        font-size: .9rem;
        font-family: $poppins-regular;
        border-radius: 10px;
        height: 41px;
        max-height: 41px;
        width: 45%!important;
        &:first-child{
          margin-inline-end: .5rem;
        }
        &:last-child{
          margin-inline-start: .5rem;
        }
      }
    }
    .button-wallet {
        background: #ffffff;
        border: 2px solid #e8e9f3;
        box-shadow: 0px 14px 50px rgba(182, 185, 213, 0.1);
        border-radius: 8px;
      }
  }
}
