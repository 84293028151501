$icomoon-font-family: "iticket-icon" !default;
$icomoon-font-path: "../../fonts/fonts"  !default;

$iticket-icon-right: "\e900";
$iticket-icon-eye: "\e912";
$iticket-icon-close: "\e911";
$iticket-icon-google: "\e913";
$iticket-icon-apple: "\e914";
$iticket-icon-minues: "\e906";
$iticket-icon-right-arrow-solid: "\e90c";
$iticket-icon-right-arrow-backup-2: "\e90d";
$iticket-icon-arrow-down: "\e90e";
$iticket-icon-arrow-left-large: "\e90f";
$iticket-icon-arrow-small-right: "\e910";
$iticket-icon-plus: "\e903";
$iticket-icon-arrow-left: "\e915";
$iticket-icon-arrow-right: "\e916";
$iticket-icon-search: "\e90a";
$iticket-icon-twitter: "\e901";
$iticket-icon-youtube: "\e902";
$iticket-icon-calendar: "\e904";
$iticket-icon-facebook: "\e905";
$iticket-icon-insta: "\e907";
$iticket-icon-location-blue-path1: "\e908";
$iticket-icon-location-blue-path2: "\e909";
$iticket-icon-map-pin-path1: "\e90b";
$iticket-icon-map-pin-path2: "\e917";

