.my-tickets_page {
    .my-tickets_header {
        .subtitle {
            color: $sec-color;
        }
    }

    // tickets-tabs
    .tickets-tabs {
        .tab {
            line-height: 21px;
            font-size: 14px;
            height: max-content;
            padding: 8px 18px;
            @include ease;
            border-radius: 6px;
            cursor: pointer;
            a{
                color: #5a5e6e;
            }
            &:not(:last-of-type) {
                margin-inline-end: 0.55rem;
            }
            &.active {
                background-color: #191a23;
                pointer-events: none;
                a{
                    color: #fff;
                }
            }
        }
    }

    .ticket-card {
        border-radius: 8.8px;
        box-shadow: 0 30px 123px rgba(182, 185, 212, 0.2);
        background-color: #fff;
        overflow: hidden;
        @include ease;
        &:hover {
            box-shadow: 0 1px 2px rgba(182, 185, 212, 0.5);
            transform: scale(0.99);
        }
        .img-container {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                inset: 0;
                background-color: rgba(25, 26, 35, 0.5);
            }
            img {
                height: 107.68px;
                object-fit: cover;
            }
            .type {
                position: absolute;
                top: 1rem;
                left: 1rem;
                color: #fff;
                border-radius: 8.8px;
                z-index: 5;
                font-size: 13px;
                padding: 0.3rem 0.75rem 0.23rem;
                &.upcoming {
                    background-color: #40c2e5;
                }
                &.previous {
                    background-color: #ea582d;
                }
            }
        }
        .title {
            font-size: 1rem;
            line-height: 22.5px;
        }
        ul {
            li {
                .icon {
                    width: 17px;
                }
                .text {
                    margin-inline-start: 0.5rem;
                    font-size: 12px;
                    color: #5a5e6e;
                }
            }
        }
        // total
        .total {
            border-top: 1px solid rgba(182, 185, 212, 0.3);
            span {
                font-size: 14px;
                &:first-child {
                    color: #9598ae;
                }
            }
        }
        .main-button {
            color: #fd6262;
        }
    }

    // refund-modal
    .refund-modal {
        .title {
            font-size: 20px;
            line-height: 30px;
        }
    }

    // done-success
    .done-success {
        img {
            width: 75px;
            height: 75px;
        }
        .text {
            color: #9598ae;
            font-size: 16px;
        }
    }
    @media screen and (min-width: 1200px) {
        .my-tickets_header {
            .subtitle {
                line-height: 24px;
            }
            .title {
                line-height: 60px;
                font-size: 40px;
            }
        }
        // tickets-tabs
        .tickets-tabs {
            .tab {
                line-height: 24.37px;
                font-size: 16.25px;
                border-radius: 10px;
                padding: 8px 28px;
                &:not(:last-of-type) {
                    margin-inline-end: 1rem;
                }
            }
        }

        .ticket-card {
            border-radius: 10px;
            box-shadow: 0 34px 140px rgba(182, 185, 212, 0.2);

            .img-container {
                img {
                    height: 122px;
                }
                .type {
                    border-radius: 10px;
                    font-size: 13.8px;
                    padding: 0.35rem 0.75rem 0.28rem;
                }
            }
            .title {
                font-size: 1rem;
                line-height: 25px;
            }
            ul {
                li {
                    .text {
                        font-size: 13px;
                    }
                }
            }
            // total
            .total {
                span {
                    font-size: 15px;
                }
            }
            .main-button {
                color: #fd6262;
                &:hover{
                    color: #fff;
                }
            }
        }

        // refund-modal
        .refund-modal {
            .title {
                font-size: 24px;
                line-height: 36px;
            }
        }

        // done-success
        .done-success {
            img {
                width: 100px;
                height: 100px;
            }
            .text {
                font-size: 13px;
            }
        }
    }

    // mobile only
    @media screen and (max-width: 767px) {
        .my-tickets_header {
            .subtitle {
                font-size: 14px;
            }
            .title {
                line-height: 36px;
                font-size: 24px;
            }
        }
    }
}
