.book-ticket_page,
.faq-page,
.sell-on-ticket_page,
.my-tickets_page,
.settings-page,
.padding-top {
    padding-top: 9vh;
    @media screen and (min-width: 1200px) {
        padding-top: 12vh;
    }
    @media screen and (min-width: 1600px) {
        padding-top: 10vh;
    }
}
.event-details_page{
    padding-top: 6.5vh;
    @media screen and (min-width: 1200px) {
        padding-top: 11vh;
    }
    @media screen and (min-width: 1600px) {
        padding-top: 10vh;
    }
}

// style Breadcrumb
.breadcrumb {
    // font-size: ;
    a,
    .iticket-icon-arrow-down {
        color: #9598ae;
    }
    .iticket-icon-arrow-down {
        font-size: 9px;
        font-weight: bold;
    }
    .current {
        color: #5a5e6e;
    }
}

// for phone input
.intl-tel-input {
    width: 100%;
    input {
        width: 100%;
    }
}

//
.modal {
    background: #71707096;
    backdrop-filter: blur(1px);
}

input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
    color: #fff;
}